var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loader)?_c('div',{staticClass:"overlay"},[_c('b-spinner',{staticClass:"loader",attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"text-white mandatory",attrs:{"for":"filter"}},[_vm._v("User Name")]),_c('b-form-input',{attrs:{"title":_vm.user.name,"disabled":!_vm.editMode || _vm.userDetailsData[0].fms_user_meter_reading_uax_id !== 0,"readonly":""},on:{"click":function($event){return _vm.showHideUserModal(true)}},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),(_vm.user.text)?_c('span',{staticClass:"clearable-icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('USER')}}},[_vm._v("×")]):_vm._e()],1),_c('b-col',{staticClass:"position-rel",attrs:{"md":"3"}},[_c('label',{attrs:{"for":"text"}},[_vm._v("User Email")]),_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c('b-row',{staticStyle:{"margin-top":"20px"}},[_c('b-col',{staticClass:"mt-0 text-centre pl-3 mt-6",attrs:{"md":"12"}},[_c('div',{},[_c('b-table',{staticClass:"bg-white",attrs:{"outlined":"","striped":"","hover":"","sticky-header":"","items":_vm.userDetailsData,"fields":_vm.userDetailsFields,"show-empty":""},scopedSlots:_vm._u([{key:"head(fms_prj_name)",fn:function(){return [_c('div',{staticClass:"mandatory position-rel"},[_vm._v("Project")])]},proxy:true},{key:"cell(fms_prj_name)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('b-form-input',{attrs:{"title":data.item.fms_prj_name,"disabled":!_vm.editMode ||
                    data.item.fms_user_meter_reading_uax_id !== 0 ||
                    !_vm.user.id,"readonly":""},on:{"click":function($event){return _vm.openValueSetModal('FMS_PRJ_INTERNAL', data.index)}},model:{value:(data.item.fms_prj_name),callback:function ($$v) {_vm.$set(data.item, "fms_prj_name", $$v)},expression:"data.item.fms_prj_name"}}),(
                  data.item.fms_prj_name &&
                    _vm.editMode &&
                    data.item.fms_user_meter_reading_uax_id === 0
                )?_c('span',{staticClass:"clearable-icon-grid",attrs:{"aria-hidden":"true"},on:{"click":function($event){return _vm.clearVsetValues('FMS_PRJ_INTERNAL', data.index)}}},[_vm._v("×")]):_vm._e()],1)]}},{key:"head(start_date)",fn:function(){return [_c('div',{staticClass:"mandatory position-rel"},[_vm._v("Start Date")])]},proxy:true},{key:"cell(start_date)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('date-picker',{attrs:{"format":"DD-MMM-YYYY","valueType":"format","disabled":!_vm.editMode || data.item.fms_user_meter_reading_uax_id !== 0},model:{value:(data.item.start_date),callback:function ($$v) {_vm.$set(data.item, "start_date", $$v)},expression:"data.item.start_date"}})],1)]}},{key:"cell(end_date)",fn:function(data){return [_c('div',{staticClass:"position-rel"},[_c('date-picker',{attrs:{"format":"DD-MMM-YYYY","valueType":"format","disabled-date":_vm.disabledDates,"disabled":!_vm.editMode || !data.item.start_date},on:{"focus":function($event){return _vm.setIndex(data.index)}},model:{value:(data.item.end_date),callback:function ($$v) {_vm.$set(data.item, "end_date", $$v)},expression:"data.item.end_date"}})],1)]}},{key:"cell(add)",fn:function(){return [_c('div',{on:{"click":function($event){return _vm.addNewRow()}}},[_c('b-img',{attrs:{"src":require('@/assets/imgs/add.png'),"fluid":"","alt":"Vatika image"}})],1)]},proxy:true},{key:"cell(remove)",fn:function(data){return [(
                data.index > 0 && data.item.fms_user_meter_reading_uax_id === 0
              )?_c('div',{on:{"click":function($event){return _vm.removeRow(data.index)}}},[_c('b-img',{attrs:{"src":require('@/assets/imgs/delete.png'),"fluid":"","alt":"Vatika image"}})],1):_vm._e()]}}])})],1)])],1),_c('b-modal',{attrs:{"no-close-on-esc":"","no-close-on-backdrop":"","hide-footer":"","size":"xl","title":"User"},on:{"hidden":function($event){return _vm.showHideUserModal(false)}},model:{value:(_vm.showUserModal),callback:function ($$v) {_vm.showUserModal=$$v},expression:"showUserModal"}},[_c('User',{attrs:{"showUserModal":_vm.showUserModal},on:{"selectedUser":_vm.selectedUser}})],1),(_vm.showValueSetModal)?_c('valueSetModal',{attrs:{"parent_value_set_id":_vm.parent_value_set_id},on:{"closeValueSetModal":_vm.closeValueSetModal,"selectedvalueSet":_vm.selectedvalueSet}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }